<ngx-loading-bar [includeBar]="true" diameter="20px"></ngx-loading-bar>
  <nav class="navbar fixed-top header-navebar">
    <div class="container">
      <nav class="navbar navbar-expand-lg pl-0 pr-0 col">
        <a class="navbar-brand mr-5" style="padding-top:7px">
          <img class="brandLogo main-logo" src="https://kcappointments.org/wp-content/uploads/2022/09/KC-Site-Logo-300px-wide.png" />
        </a>
  
        <!--<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </button>-->
  
        <div class="navbar-collapse collapse" id="navbarNav">
          <ul class="list-unstyled navbar-nav">
            <ng-container *ngFor="let item of menuItems">
              <ng-container *ngIf="!item.subs || (item.subs &&  item.subs.length===0)">
                <li *ngxPermissionsOnly="[item.permissions]" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:false}" class="nav-item">
                  <a *ngIf="item.newWindow" [routerLink]="[item.to]" rel="noopener noreferrer" target="_blank" class="nav-link">
                    <span class="hide-menu"> {{item.title}}</span>
                  </a>
                  <a *ngIf="!item.newWindow"
                     [routerLink]="item.to" class="nav-link">
                    <span class="hide-menu">{{item.title}}</span>
                  </a>
                </li>
              </ng-container>
              <ng-container *ngIf="item.subs && item.subs.length !== 0">
                <li class="nav-item" dropdown *ngxPermissionsOnly="item.permissions" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:false}">
                  <a class="nav-link dropdown-toggle" dropdownToggle href="javascript:;" role="button">
                    {{item.title}}
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu">
                    <ng-container *ngFor="let sub of item.subs">
                      <ng-container *ngxPermissionsOnly="sub.permissions">
                        <a class="dropdown-item" [routerLink]="[sub.to]">{{sub.title}}</a>
                      </ng-container>
                    </ng-container>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </nav>
  
      <div class="col-auto">
        <div class="navbar-right ">
          <div class="user d-inline-block" dropdown>
            <button class="btn btn-empty p-0" type="button" dropdownToggle>
              <span class="name">Welcome </span>
              <span class="user-image">
                <img alt="Profile Picture" [src]="safeSrc" />
              </span>
            </button>
  
            <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
              <a class="dropdown-item c-pointer" routerLink="/change-password"><i class="fa fa-lock"></i>Change Password</a>
              <span class="divider dropdown-divider"></span>
              <a class="dropdown-item c-pointer" (click)="onSignOut()"><i class="fas fa-sign-out-alt"></i>Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
