<div class="tab-container">
  <div class="fix-nav-tabs">
    <ul class="nav nav-tabs mb-0">
      <li class="nav-item" *ngFor="let tab of tabs" [tab]="tab" [className]="className" tabLink></li>
    </ul>
  </div>
  <div class="tab-body">
    <router-outlet></router-outlet>
  </div>
</div>
