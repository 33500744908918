import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { AuthGuard } from '../shared/guard/auth.guard';
import { ViewsComponent } from './views.component';
import { LoginComponent } from './authentication/login/login.component';
import { NgxPermissionsGuard } from 'ngx-permissions';

let routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'assessments/tabs/assessment-list' },
  {
    path: '',
    component: ViewsComponent,
    children: [
      {
        path: 'assessments',
        loadChildren: () => import('../assessment/assessment.module').then(m => m.AssessmentModule),
        canLoad: [AuthGuard]
      },
       {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: '1128',
            redirectTo: 'error/404'
          },
        }
       },
      {
        path: 'dashboard-tc',
        loadChildren: () => import('./dashboard-tc/dashboard-tc.module').then(m => m.DashboardTCModule),
        canLoad: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: '0',
            redirectTo: 'error/404'
          },
        }
      },
      //{
      //  path: 'documents',
      //  loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
      //  canLoad: [AuthGuard]
      //},
      {
        path: 'change-password',
        loadChildren: () => import('./admin/change-password/change-password.module').then(m => m.ChangePasswordModule),
        canLoad: [AuthGuard]
      }
    ],
  },
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: '/error'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewRoutingModule { }
