import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { httpInterceptorProviders } from './shared/http-Interceptors';
import { ConfirmationDialogService } from './shared/confirmation-dialog/confirmation-dialog.service';
import { ConfirmationSpecialDialogService } from './shared/confirmation-special-dialog/confirmation-special-dialog.service';
import { CanDeactivateGuard, canDeactivateGuardProvider } from './shared/guard/auth.guard';
import { CanDeactivateSpeacialGuard, CanDeactivateNewGuard} from './shared/guard/auth.guard';
import { provideUserIdleConfig } from 'angular-user-idle'
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { PermissionsService } from './shared/permissions.service';
import { ImagePickerService } from './shared/image-picker/image-picker.service';
//import { DocumentViewerPickerService } from './shared/document-viewer-picker/document-viewer-picker.service';
import { environment } from '../environments/environment';
import { TinyMceService } from './shared/tinymce.service';
//import { ValidationMessageToasterComponent } from './shared/http-Interceptors/validation-message-toaster/validation-message-toaster.component';
import { ViewsComponent } from './views/views.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';



export function createNgxLoader(ngx: NgxPermissionsService, permissionsService: PermissionsService) {
  return () => {
    let menus = permissionsService.menus();
    if (menus && menus.length != 0) {
      let filteredMenus = menus.filter(x => x.AccessTypeId != null);
      let permissions: any[] = filteredMenus.map(x => x.Id);
      return ngx.loadPermissions(permissions);
    }
  };
}

@NgModule({
  imports: [
    BrowserModule,
    
    NgxPermissionsModule.forRoot(),
    ViewsModule,
    //LayoutContainersModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    HttpClientModule,
    RouterModule,
    NgbModule,
    AppRoutingModule
    
  ],
  declarations: [
    AppComponent,
    //ValidationMessageToasterComponent
    //ViewsComponent,
  ],
  providers: [
    ImagePickerService,
    //DocumentViewerPickerService,
    NgxPermissionsService,
    provideUserIdleConfig({ idle: 600, timeout: 300, ping: 120 }),
    {
      provide: APP_INITIALIZER,
      useFactory: createNgxLoader,
      deps: [NgxPermissionsService, PermissionsService],
      multi: true
    },
    httpInterceptorProviders,
    ConfirmationDialogService,
    ConfirmationSpecialDialogService,
    CanDeactivateGuard,
    TinyMceService,
    CanDeactivateSpeacialGuard,
    canDeactivateGuardProvider
  ],
  bootstrap: [AppComponent],
  //exports: [ValidationMessageToasterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
