export const environment = {
  name: 'UAT',
  production: true,
  kcassUrl: 'https://qcass-uat.azurewebsites.net/',
  qccsUrl: 'https://qcass-uat.azurewebsites.net/assessments/tabs/assessment-list',
  apiUrl: "https://qccms-api-uat.azurewebsites.net/api/",
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1170,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'colourScheme',
  isMultiColorActive: true,
  defaultColor: 'light.blueolympic',
  defaultRadiusStorageType: 'flat',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'flat',
  isAuthGuardActive: true,
  filePickerApi: {
    key: 'AqCmIA40Rta5UyDRy2dbMz',
    policy: 'eyJleHBpcnkiOjQxMDIzNDA0MDAsImNhbGwiOlsicmVhZCIsInN0b3JlIiwiY29udmVydCIsInJlbW92ZSJdfQ==',
    signature: '674a4c936d7e19b36ed6f1b032133706165ff693c167748c027879fb94b5bfdd',
    path: '/qcass-uat/',
    location: 'azure'
  },
  userIdle: { idle: 900, timeout: 5, ping: 0 }
};
