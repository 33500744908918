import { Injectable } from "@angular/core";
declare let tinymce: any;

@Injectable({
  providedIn: "root",
})
export class TinyMceService {
  constructor() { }

  initDefaultEditor(id: string, form: any, isRequired: boolean = false): any {
    let that = this;
    tinymce.init({
      height: 500,
      selector: "#" + id,
      language: 'en_GB',
      language_url: 'assets/tinymice/en_GB.js',
      setup: function (editor :any) {
        editor.on("keyup", function () {
          //var maxNum = 500;
          form.controls[id].markAsDirty();
          //setTimeout(() => {
          //  let wordCountDiv = document.querySelector('.tox-statusbar__wordcount');
          //  if (wordCountDiv) {
          //    wordCountDiv.textContent = editor.getContent() + ' / ' + maxNum + ' ' + "Characters";
          //  }
          //}, 100)
          
          if (isRequired) {
            const editorContent = tinymce.get(id).getContent();
            if (editorContent == "") {
              form.controls[id].setErrors({ incorrect: true });
            } else {
              form.controls[id].setErrors(null);
            }
          }
        });
        editor.on('init', function() {
          var maxNum = 5000;
          var footer = $('<div>', {
            class: 'tox-statusbar__wordcount',
            text: that.countCharacters(editor.getContent()) + ' / ' + maxNum + ' ' + "Characters"
          });
          $(editor.getContainer()).append(footer);
          editor.on('input change', function () {
            form.controls[id].markAsDirty();
            var textCount = that.countCharacters(editor.getContent());
            if (textCount > maxNum) {
              alert("Only a maximum 5000 characters allowed")

              var truncateHtml = that.truncateHTMLContent(editor.getContent(), maxNum);
              tinymce.get(id).contentDocument.body.innerHTML = truncateHtml;
              setTimeout(() => {
                var newTT = that.countCharacters(editor.getContent())
                if (newTT === (maxNum - 1)) {
                  let tempDiv = document.createElement('div');
                  tempDiv.innerHTML = truncateHtml;

                  // Find the last element
                  let lastElement = tempDiv.lastElementChild;

                  // Remove trailing whitespace
                  if (lastElement) {
                    lastElement.innerHTML = lastElement.innerHTML.replace(/\s+$/, '');
                  }

                  // Insert &nbsp; before the closing of the last element
                  if (lastElement) {
                    lastElement.insertAdjacentHTML('beforeend', '&nbsp;');
                  }

                  // Get the updated HTML string
                  let updatedHtmlString = '<html><head></head><body>' + tempDiv.innerHTML + '</body><html>';
                  tinymce.get(id).contentDocument.body.innerHTML = updatedHtmlString;


                  const lastNode = editor.getBody().lastChild;
                  const range = editor.selection.getRng();
                  range.selectNodeContents(lastNode);
                  range.collapse(false); // Collapse the range to the end
                  editor.selection.setRng(range);
                  editor.selection.scrollIntoView();
                }
              }, 0)

              setTimeout(() => {
                footer.text(that.countCharacters(editor.getContent()) + ' / ' + maxNum + ' ' + "Characters")
              }, 100)
            } else {
              setTimeout(() => {
                footer.text(that.countCharacters(editor.getContent()) + ' / ' + maxNum + ' ' + "Characters")
              }, 100)
              return ""
            }

            return ""
          });
          editor.on('submit', function() {
            var abc =  footer.text(editor.getContent().replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g, "").length + ' / ' + maxNum + ' ' + "Characters");
            var count =  editor.getContent().replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g, "").length
         
            if(count > maxNum ){
              alert("Only a maximum 5000 characters allowed")
            }else{
              return ""
            }
            return ""
          });
          editor.on('input keydown', function() {
            form.controls[id].markAsDirty();
            footer.text(that.countCharacters(editor.getContent()) + ' / ' + maxNum + ' ' + "Characters")
          });
        });
      },
      spellchecker_language: "en_GB",
      branding: false,
      forced_root_block: "p",
      extended_valid_elements:
        "img[class=myclass|!src|border:0|alt|title|width|height|style|span|div|code]",
      deprecation_warnings: false,
      content_langs: [
        { title: "English (US)", code: "en_US" },
        { title: "English (UK)", code: "en_UK" },
        { title: "Spanish", code: "es" },
        { title: "French", code: "fr" },
        { title: "German", code: "de" },
        { title: "Portuguese", code: "pt" },
        { title: "Chinese", code: "zh" },
      ],
      contextmenu: false,
      menubar: false,
      menu: {
        file: { title: 'File', items: 'newdocument' },
        edit: { title: 'Edit', items: 'undo redo | selectall' },
        view: { title: 'View', items: 'code | visualaid | spellchecker' },
        insert: { title: 'Insert', items: 'image link media inserttable | emoticons' },
        table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' },
        tools: { title: 'Tools', items: 'spellchecker | wordcount' }
      },
      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
      plugins: ['autoresize',"anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount "],
        
      toolbar:
        "bold italic underline numlist bullist indent outdent undo redo",
    });
  }
  initSmallEditor(id: string, onchangecallback :any, that :any): any {
    tinymce.init({
      selector: "#" + id,
      language: 'en_GB',
      language_url: 'assets/tinymice/en_GB.js',
      height: 100,
      spellchecker_language: "en_GB",
      branding: false,
      forced_root_block: 'p',
      extended_valid_elements:
        "img[class=myclass|!src|border:0|alt|title|width|height|style|span|div|code]",
      deprecation_warnings: false,
      content_langs: [
        { title: "English (US)", code: "en_US" },
        { title: "English (UK)", code: "en_UK" },
        { title: "Spanish", code: "es" },
        { title: "French", code: "fr" },
        { title: "German", code: "de" },
        { title: "Portuguese", code: "pt" },
        { title: "Chinese", code: "zh" },
      ],
      contextmenu: false,
      menubar: false,
      menu: {
        file: { title: 'File', items: 'newdocument' },
        edit: { title: 'Edit', items: 'undo redo | selectall' },
        view: { title: 'View', items: 'code | visualaid | spellchecker' },
        insert: { title: 'Insert', items: 'image link media inserttable | emoticons' },
        table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' },
        tools: { title: 'Tools', items: 'spellchecker | wordcount' }
      },
      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
      plugins:
        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode",
      toolbar:
        "bold italic underline strikethrough | backcolor forecolor",
      statusbar: false,
      setup: function (editor :any) {
        editor.on('Paste Change input Undo Redo', function (evt :any) { onchangecallback(evt, that) });
      }
    });
  }

  initDefaultDynamicEditor(id: string, onchangecallback :any, that :any): any {
    tinymce.init({
      selector: "#" + id,
      height: 500,
      language: 'en_GB',
      language_url: 'assets/tinymice/en_GB.js',
      spellchecker_language: "en_GB",
      branding: false,
      forced_root_block: 'p',
      extended_valid_elements:
        "img[class=myclass|!src|border:0|alt|title|width|height|style|span|div|code]",
      deprecation_warnings: false,
      content_langs: [
        { title: "English (US)", code: "en_US" },
        { title: "English (UK)", code: "en_UK" },
        { title: "Spanish", code: "es" },
        { title: "French", code: "fr" },
        { title: "German", code: "de" },
        { title: "Portuguese", code: "pt" },
        { title: "Chinese", code: "zh" },
      ],
      contextmenu: false,
      menubar: false,
      menu: {
        file: { title: 'File', items: 'newdocument' },
        edit: { title: 'Edit', items: 'undo redo | selectall' },
        view: { title: 'View', items: 'code | visualaid | spellchecker' },
        insert: { title: 'Insert', items: 'image link media inserttable | emoticons' },
        table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' },
        tools: { title: 'Tools', items: 'spellchecker | wordcount' }
      },
      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
      plugins:
        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode",
      toolbar:
        "bold italic underline checklist numlist bullist indent outdent undo redo",
      setup: function (editor :any) {
        editor.on('Paste Change input Undo Redo', function (evt :any) { onchangecallback(evt, that) });
      }
    });
  }


  truncateHTMLContent(htmlString :any, maxLength :any) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(htmlString, 'text/html');
    let truncatedDoc = doc.cloneNode(true) as Document;
    let truncatedLength = 0;

    function truncateNode(node :any) {
      if (node.nodeType === Node.TEXT_NODE) {
        let remaining = maxLength - truncatedLength;
        if (remaining > 0) {
          let truncatedText = node.textContent.substring(0, remaining);
          truncatedLength += truncatedText.length;
          node.textContent = truncatedText;
        } else {
          node.textContent = '';
        }
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          truncateNode(node.childNodes[i]);
          if (truncatedLength >= maxLength) {
            while (node.childNodes.length > i + 1) {
              node.removeChild(node.lastChild);
            }
            break;
          }
        }
      }
    }

    truncateNode(truncatedDoc.documentElement);
    return truncatedDoc.documentElement.outerHTML;
  }

  countCharacters(htmlString :any) {
    const tempElement :any = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent.length;
  }

}
