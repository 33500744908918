export const enum DateTypes {
  FullDay = 1158,
  Morning = 1159,
  Afternoon = 1160,
};

export enum CalendarView {
  Day = 1,
  Week = 2,
  Month = 3,
  Team = 4,
  User = 5,
};

export const enum AccessType {
  ReadOnly = -9968,
  AddEdit = -9967,
  AddEditDelete = -9966,
  Show = -9965,
  Hide = -9964
}

export const enum MenuPermission {
  Unknown = -1,
  DashboardTC = 0,
  MyDocuments = 1164,
  GeneralDocuments = 1165,
  Dashboard = 1128,
  Grading = 2872,
  ApplicationForm = -10,
}

export const enum DashboardPermission {
  PortalInvestmentSummary = -9963,
  PortalInvestmentChart = 1129,
  PortalProtocolSummary = 1130,
  PortalProtocolChart = 1131,
  PortalProtocolCategorySummary = 1132,
  PortalProtocolCategoryChart = 1133,
  PortalProtocolEcosystemSummary = 1134,
  PortalProtocolEcosystemChart = 1135,
  PortalProtocolLiquiditySummary = 1136,
  PortalProtocolLiquidityChart = 1137,
}

export const enum DayTypes {
  FullDay = 1158,
  FirstHalf = 1159,
  SecondHalf = 1160,
  WeekendColor = '#d9d9d9'
};


export const emailSuccCodeText = 'A code has been sent to your email address'
export const phonSuccCodeText = 'A code has been sent to your mobile number'


export const saveChangeDialogText = 'You have made changes to this page. Please click Save Changes to save and continue, or click Cancel to continue without saving'
