import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import menuItems, { IMenuItem } from '../../constants/menu';
import { PermissionsService } from '../../shared/permissions.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {

  menuItems: IMenuItem[] = menuItems;
  ordering: any = [];
  menu: any = [];
  permissions: any;

  constructor(
    private router: Router,
    private permissionsService: PermissionsService
  ) { }

  ngOnInit() {
    document.body.classList.add('background');
    var count = 0;
    let menus = this.permissionsService.menus();
    let filteredMenus = menus.filter(x => x.AccessTypeId != null);
    this.permissions = filteredMenus.map(x => x.Id);
    for (var i = 0; i < menuItems.length; i++) {
      if (menuItems[i].to == '') {
        for (var j = 0; j < menuItems[i].permissions.length; j++) {
          this.ordering[count] = menuItems[i].permissions[j];
          this.menu.push(menuItems[i].subs[j]);
          count++;
        }
      }
      else {
        this.ordering[count] = menuItems[i].permissions[0];
        this.menu.push(menuItems[i]);
        count++;
      }
    }
  }

  ngOnDestroy() {
    document.body.classList.remove('background');
  }

  goBack() {
    let activemenu: any;
    for (var i = 0; i < this.ordering.length; i++) {
      let activePermission = this.permissions.find((x:any) => x == this.ordering[i]);
      if (activePermission != undefined) {
        activemenu = this.menu.find((m :any) => m.permissions[0] == activePermission);
        if (activemenu) {
          break;
        }
      }
    }
    if (activemenu) {
      this.router.navigate([activemenu.to]);
    }
    else {
      this.router.navigate(['/assessments/tabs/assessment-list']);
    }
  }

}
