import { Injectable } from '@angular/core';
import { AccessType } from '../constants/constants';

@Injectable({ providedIn: 'root' })
export class PermissionsService {

  constructor(
  ) { }

  currentUser(): any {
    return JSON.parse(localStorage.getItem('currentUser')!);
  }

  userProfileImageHandler(): string {
    let currentUser = this.currentUser();
    let ProfileImageAttachmentFileHandle: string = (currentUser && currentUser.ProfileImageAttachmentFileHandle) ? (currentUser.ProfileImageAttachmentFileHandle ? currentUser.ProfileImageAttachmentFileHandle : '') : '';
    return ProfileImageAttachmentFileHandle;
  }

  applicationSubMenu(): string {
    let currentUser = this.currentUser();
    let applicationSubMenuName: string = (currentUser && currentUser.ApplicationSubMenu) ? currentUser.ApplicationSubMenu : '';
    return applicationSubMenuName;
  }

  isUserReadonly(): boolean {
    let currentUser = this.currentUser();
    let isReadonly: boolean = (currentUser && currentUser.isReadonly) ? currentUser.isReadonly : false;
    return isReadonly;
  }

  menus(): any[] {
    let currentUser = this.currentUser();
    let menus: any[] = (currentUser && currentUser.menus) ? currentUser.menus : [];
    return menus;
  }

  dashboardItems(): any[] {
    let currentUser = this.currentUser();
    let dashboardItems: any[] = (currentUser && currentUser.dashboardItems) ? currentUser.dashboardItems : [];
    return dashboardItems;
  }

  dashboardPermission(id: number): any {
    let that = this;
    const dashboardItems = that.dashboardItems();
    const permissions: any = {};
    let accessType: any;
    const dashboardItem = dashboardItems.find(x => x.Id === id);
    if (dashboardItem) {
      accessType = dashboardItem['AccessTypeId'];
      if (accessType) {
        if (accessType == AccessType.ReadOnly) {
          permissions.isReadonly = true;
          permissions.show = true;
        }
        if (accessType == AccessType.Show) {
          permissions.show = true;
        }
        if (accessType == AccessType.Hide) {
          permissions.show = false;
        }
      }
    }
    const currentUser = this.currentUser();
    if (currentUser) {
      permissions.isUserReadonly = currentUser.isReadonly;
      permissions.isDeleteDisabled = currentUser.isDeleteDisabled;
    }
    if (permissions.isDeleteDisabled) {
      permissions.isDeleteHidden = true;
    }
    if (!permissions.isUserReadonly && dashboardItem && accessType) {
      if (accessType === accessType.AddEdit || accessType === AccessType.ReadOnly) {
        permissions.isDeleteHidden = true;
      }
      if (accessType === AccessType.ReadOnly) {
        permissions.isSaveHide = true;
      }
    }
    return permissions;
  }

  menuPermission(id: number): any {
    let that = this;
    let menues = that.menus();
    let permissions: any = {};
    let accessType: any;
    let menu = menues.find(x => x.Id == id);
    if (menu) {
      accessType = menu['AccessTypeId'];
      if (accessType) {
        if (accessType == AccessType.ReadOnly) {
          permissions.isReadonly = true;
          permissions.show = true;
        }
        if (accessType == AccessType.Show) {
          permissions.show = true;
        }
        if (accessType == AccessType.Hide) {
          permissions.show = false;
        }
      }
    }
    let currentUser = this.currentUser();
    if (currentUser) {
      permissions.isUserReadonly = currentUser.isReadonly;
      permissions.isDeleteDisabled = currentUser.isDeleteDisabled;
    }
    if (permissions.isDeleteDisabled) {
      permissions.isDeleteHidden = true;
    }
    if (!permissions.isUserReadonly && menu && accessType) {
      if (accessType == AccessType.AddEdit || accessType == AccessType.ReadOnly) {
        permissions.isDeleteHidden = true;
      }
      if (accessType == AccessType.ReadOnly) {
        permissions.isSaveHide = true;
      }
    }
    return permissions;
  }

}
