import { MenuPermission } from './constants';

export interface IMenuItem {
  id?: string;
  icon?: string;
  title: string;
  label?: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  permissions: number[];
}

const Menu: IMenuItem[] | any = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    to: '/dashboard',
    permissions: [MenuPermission.Dashboard]
  },
  {
    id: 'dashboard-tc',
    title: 'Agreements & Policies',
    to: '/dashboard-tc',
    permissions: [MenuPermission.DashboardTC]
  },
  {
    id: 'documents',
    title: 'Documents',
    to: '',
    permissions: [MenuPermission.MyDocuments, MenuPermission.GeneralDocuments],
    subs: [
      {
        id: 'my-documents',
        title: 'My Documents',
        to: '/documents/my-documents',
        permissions: [MenuPermission.MyDocuments]
      },
      {
        id: 'general-documents',
        title: 'General Documents',
        to: '/documents/general-documents',
        permissions: [MenuPermission.GeneralDocuments]
      }
    ]
  }
];
export default Menu;
