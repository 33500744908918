import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
//import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationSpecialDialogComponent } from './confirmation-special-dialog/confirmation-special-dialog.component';
import { UnsavedChanges } from './unsaved-changes/unsaved-changes.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';
//import { FilestackModule } from '@filestack/angular';
//import { DataTablesModule } from 'angular-datatables';
//import { FilePickerComponent } from './file-picker/file-picker.component';
//import { DocumentViewerPickerComponent } from './document-viewer-picker/document-viewer-picker.component';
//import { CollapseModule, BsDropdownModule, TooltipModule } from 'ngx-bootstrap';

//import { NgbModule, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
//import { PreviewDocumentModelComponent } from './document-viewer-picker/preview-document-model/preview-document-model.component';
//import { AttachmentPreviewModelComponent } from './attachment-preview-model/attachment-preview-model.component';
//import { NgxPaginationModule } from 'ngx-pagination';
import { TabsComponent } from '../shared/tabs/tabs.component';
import { TabLinkComponent } from '../shared/tabs/tab-link.component';
//import { StateButtonComponent } from '../components/state-button/state-button.component';
import { CryptoService } from './crypto/crypto.service';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    //PerfectScrollbarModule,
    ModalModule.forRoot(),
    //FilestackModule,
    //DataTablesModule,
    FormsModule,
    //CollapseModule,
    //NgbModule,
    //TooltipModule.forRoot(),
    //BsDropdownModule.forRoot(),
    //NgxPaginationModule
  ],
  declarations: [
    ImagePickerComponent,
    //DocumentViewerPickerComponent,
    ErrorComponent,
    ConfirmationDialogComponent,
    ConfirmationSpecialDialogComponent,
    UnsavedChanges,
    //FilePickerComponent,
    //PreviewDocumentModelComponent,
    //AttachmentPreviewModelComponent,
    TabsComponent,
    TabLinkComponent,
    //StateButtonComponent
   
  ],
  exports: [
    ImagePickerComponent,
    //DocumentViewerPickerComponent,
    //PerfectScrollbarModule,
    ErrorComponent,
    //DataTablesModule,
    ConfirmationDialogComponent,
    ConfirmationSpecialDialogComponent,
    UnsavedChanges,
    //FilePickerComponent,
    //AttachmentPreviewModelComponent,
    TabsComponent,
    TabLinkComponent
  ],
  providers: [
    //ImagePickerComponent,
    //DocumentViewerPickerComponent,
    //FilePickerComponent,
    CryptoService
  ],
 
})
export class SharedModule { }
