import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class HttpService {
  isSaveForm: boolean = false;

  constructor(
    private http: HttpClient
  ) {
    this.isSaveForm = false;
  }

  get(url: string): Observable<any> {
    return this.http.get(url);
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(url, data);
  }

  put(url: string, data: any): Observable<any> {
    return this.http.put(url, data);
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url);
  }

  currentUser(): any {
    return JSON.parse(localStorage.getItem('currentUser')!);
  }

  userName(): string {
    let currentUser = this.currentUser();
    let name: string = (currentUser && currentUser.userName) ? currentUser.userName : '';
    return name;
  }

  userId(): number {
    let currentUser = this.currentUser();
    let id: number = (currentUser && currentUser.user.Id) ? currentUser.user.Id : 0;
    return id;
  }

  appInfo(): any {
    let appInfo: any = {};
    let currentUser = this.currentUser();
    appInfo.database = (currentUser && currentUser.database) ? currentUser.database : '';
    appInfo.version = (currentUser && currentUser.version) ? currentUser.version : '';
    appInfo.lastUpdated = (currentUser && currentUser.lastUpdated) ? currentUser.lastUpdated : '';
    return appInfo;
  }

}
