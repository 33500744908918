import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from '../../../shared/http.service';

@Component({
  selector: 'app-color-switcher',
  templateUrl: './color-switcher.component.html',
})
export class ColorSwitcherComponent implements OnInit {

  colors: string[] = ['bluenavy', 'blueyale', 'blueolympic', 'greenmoss', 'greenlime', 'purplemonster', 'orangecarrot', 'redruby', 'yellowgranola', 'greysteel'];
  selectedColor: string = localStorage.getItem(environment.themeColorStorageKey) || environment.defaultColor;
  toggleClass = 'theme-colors';
  radius = localStorage.getItem(environment.themeRadiusStorageKey) || environment.defaultRadiusStorageType;
  @Input() bsModalRef!: BsModalRef;

  constructor(
    private renderer: Renderer2,
    private httpService: HttpService
  ) {
  }

  ngOnInit() {

  }

  changeColor(color: string) {
    this.selectedColor = color;
    localStorage.setItem(environment.themeColorStorageKey, color);
    this.submitData();
  }

  changeRadius(radius :any) {
    this.radius = radius;
    if (radius === 'flat') {
      this.renderer.removeClass(document.body, 'rounded');
    } else {
      this.renderer.addClass(document.body, 'rounded');
    }
    this.submitData();
    localStorage.setItem(environment.themeRadiusStorageKey, this.radius);
  }

  submitData() {
    let that = this;
    let data: any = {};
    const url = "InternalCustomisationSetting/SaveColorScheme";

    data.ColourScheme = that.selectedColor;
    data.RoundButton = that.radius;

    this.httpService
      .post(url, data)
      .subscribe(response => {
        window.location.reload();
      }, error => {
      });

  }
  
}
