import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ImagePickerService } from './image-picker.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.css']
})
export class ImagePickerComponent implements OnChanges {

  @Input() labeltext!: string;
  @Input() className!: string;
  @Input() required!: boolean;
  @Input() fileHandle!: string;
  @Input() fpOptions: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  filedata: any = {};
  options: any = {};
  isUploaded: boolean = false;
  safeSrc!: SafeResourceUrl;

  constructor(
    private imagePickerService: ImagePickerService,
    private loadingBar: LoadingBarService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    let that = this;
    that.imagePickerService.uploadDone.subscribe(files => {
      that.uploadDone(files);
    });
    if (changes.fileHandle.currentValue) {
      that.loadingBar.start();
      that.imagePickerService.get(changes.fileHandle.currentValue)
        .then(function (fileMetData: any) {
          that.loadingBar.complete();
          that.filedata.FileHandle = fileMetData.handle;
          that.filedata.FileName = fileMetData.filename;
          that.filedata.FileSize = fileMetData.size;
          that.filedata.MimeType = fileMetData.mimetype;
          that.isUploaded = true;
          that.safeSrc = that.sanitizer.bypassSecurityTrustResourceUrl(that.imagePickerService.getUrl(changes.fileHandle.currentValue));
        })
        .catch(function () {
          that.loadingBar.complete();
        });
    }
  }

  upload() {
    let that = this;
    if (that.fpOptions) {
      that.options = that.fpOptions;
    }
    that.imagePickerService.open(that.options);
  }

  uploadDone(files: any) {
    let that = this;
    if (files.length == 1) {
      let fileMetData = files[0];
      that.filedata.FileHandle = fileMetData.handle;
      that.filedata.FileName = fileMetData.filename;
      that.filedata.FileSize = fileMetData.size;
      that.filedata.MimeType = fileMetData.mimetype;
      that.fileHandle = fileMetData.handle;
      that.isUploaded = true;
      that.notify.emit(that.filedata);
      that.safeSrc = that.sanitizer.bypassSecurityTrustResourceUrl(that.imagePickerService.getUrl(that.fileHandle));
    }
  }

  download() {
    let that = this;
    saveAs(that.imagePickerService.getUrl(that.filedata.FileHandle), that.filedata.FileName);
  }

  remove() {
    let that = this;
    that.filedata = {};
    that.fileHandle = "";
    that.safeSrc = null as any;  
    that.isUploaded = false;
    that.notify.emit(that.filedata);
  }

  removeFromFilePicker() {
    let that = this;
    that.imagePickerService.remove(that.fileHandle)
      .then(function () {
        that.filedata = {};
        that.fileHandle = "";
        that.isUploaded = false;
        that.notify.emit(that.filedata);
      }).catch(function () {
      });
  }
}

