import { Component, OnInit, OnDestroy, HostListener, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ColorSwitcherComponent } from 'src/app/containers/layout/color-switcher/color-switcher.component';
import { AuthService } from '../../../views/authentication/authentication.service';
import menuItems, { IMenuItem } from '../../../constants/menu';
import { PermissionsService } from '../../../shared/permissions.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ImagePickerService } from '../../../shared/image-picker/image-picker.service';
import { HttpService } from '../../../shared/http.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html'
})
export class TopnavComponent implements OnInit, OnDestroy {

  api2: string = 'lookup/vw_LookupAssessor/';
  api: string = "Report/";
  menuItems: IMenuItem[] = menuItems;
  ordering: any = [];
  menu: any = [];
  permissions: any;
  sidebar!: ISidebar;
  subscription!: Subscription;
  displayName:any;
  isFullScreen = false;
  modalRef!: BsModalRef;
  logo: any;
  sublogo: any;
  safeSrc!: SafeResourceUrl;
  environment: any = environment;

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private modal: BsModalService,
    private sanitizer: DomSanitizer,
    private imagePickerService: ImagePickerService,
    private permissionsService: PermissionsService,
    private httpService: HttpService
  ) {
  }

  fullScreenClick() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  currentUrl(): string {
    return this.router.url;
  }


  ngOnInit() {
    //this.displayName = this.authService.userName();
    var cUser = JSON.parse(localStorage.getItem('currentUser')!);
    this.displayName = "Assessor";
    
    this.subscription = this.sidebarService.getSidebar().subscribe(
      res => {
        this.sidebar = res;
      },
      err => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
    var count = 0;
    let menus = this.permissionsService.menus();
    let filteredMenus = menus.filter(x => x.AccessTypeId != null);
    this.permissions = filteredMenus.map(x => x.Id);
    for (var i = 0; i < menuItems.length; i++) {
      if (menuItems[i].to == '') {
        for (var j = 0; j < menuItems[i].permissions.length; j++) {
          this.ordering[count] = menuItems[i].permissions[j];
          this.menu.push(menuItems[i].subs[j]);
          count++;
        }
      }
      else {
        this.ordering[count] = menuItems[i].permissions[0];
        this.menu.push(menuItems[i]);
        count++;
      }
    }
    var ProfileImageHandler = this.permissionsService.userProfileImageHandler();
    this.safeSrc = ProfileImageHandler ? this.sanitizer.bypassSecurityTrustResourceUrl(this.imagePickerService.getUrl(ProfileImageHandler)) : 'assets/img/profile-pic-1.png';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (e: { stopPropagation: () => void; }, menuClickCount: number, containerClassnames: string) => {
    if (e) { e.stopPropagation(); }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (event: { stopPropagation: () => void; }, containerClassnames: string) => {
    if (event) { event.stopPropagation(); }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut() {
    this.authService.signOut();
  }

  goToHome() {
    let activemenu: any;
    for (var i = 0; i < this.ordering.length; i++) {
      let activePermission = this.permissions.find((x:any) => x == this.ordering[i]);
      if (activePermission != undefined) {
        activemenu = this.menu.find((m:any) => m.permissions[0] == activePermission);
        if (activemenu) {
          break;
        }
      }
    }
    if (activemenu) {
      this.router.navigate([activemenu.to]);
    }
    else {
      this.router.navigate(['/assessments/tabs/assessment-list']);
    }
  }

  gotoDashboard() {
    this.router.navigate(['/assessments/tabs/assessment-list']);
  }

  download() {
    this.httpService
      .get(this.api).subscribe((res: any) => {
        var blob = this.b64toBlob(res.Content, res.ContentType);
        saveAs(blob, res.FileName);

      });
  }

  b64toBlob(b64Data :any, contentType :any) {
    var sliceSize = 512;
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}
