<form>
  <div class="card form-card mb-0">
    <div class="card-header header-prompt">
      <h3 class="card-title text-white text-center mb-0 p-0">{{ title }}</h3>
    </div>
    <div class="card-block" [innerHTML]="message">
    </div>
    <div  class="text-right card-footer">
      <button type="button" class="btn btn-primary saveDial" (click)="accept()">{{ btnOkText }}</button>
      <button *ngIf="btnCancelText" type="button" class="btn btn-dark" (click)="decline()">{{ btnCancelText }}</button>
    </div>
  </div>
</form>
