import { inject, Injectable } from '@angular/core';
import { Router, CanLoad, CanDeactivate, ActivatedRoute, RouterEvent, NavigationStart, CanDeactivateFn} from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ConfirmationSpecialDialogService } from '../confirmation-special-dialog/confirmation-special-dialog.service';
import { filter } from 'rxjs/operators';
import { saveChangeDialogText } from 'src/app/constants/constants';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

export const CAN_DEACTIVATE_GUARD = 'CAN_DEACTIVATE_GUARD';

export interface CanComponentDeactivate {
  canDeactivate: (
    targetRoute: string
  ) => Observable<boolean> | Promise<boolean> | boolean;
  form?: { dirty: boolean };
}

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad {

  constructor(private router: Router) { }

  canLoad() {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url and return false
    this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: this.router.url } });
    return false;
  }
}

export class CanDeactivateGuard implements CanDeactivate<FormComponent> {

  canDeactivate(component: any) {
    var currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    if (component.form && component.form.dirty) {
      return component.form.dirty ? currentUser ? component.confirmationDialogService.confirm('Warning - Unsaved Changes',
        '<p>Please save changes before leave this page</p>',
        'Save Changes') : true : true;
    }
    return true;
  }
}
export class CanDeactivateSpeacialGuard implements CanDeactivate<FormComponent> {

  canDeactivate(component: any) {
    var currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    if (component.form && component.form.dirty) {
      return component.form.dirty ? currentUser ? component.confirmationSpecialDialogService.confirm('Warning - Unsaved Changes',
      `<p>${saveChangeDialogText}</p>`,
        'Save Changes') : true : true;
    }
    return true;
  }
}



export const CanDeactivateNewGuard: CanDeactivateFn<FormComponent> = (component, currentRoute, currentState, nextState) => {
  const confirmationDialogService = inject(ConfirmationDialogService);

  // Check if either form is dirty
  if (component.form && component.form.dirty) {
    return confirmationDialog(component.form, confirmationDialogService);
  }

  if (component.duelForm && component.duelForm.dirty) {
    return confirmationDialog(component.duelForm, confirmationDialogService);
  }

  return true;
};

// Helper function for confirmation dialog
const confirmationDialog = (form: any, confirmationDialogService: ConfirmationDialogService) => {
  return form.dirty 
    ? confirmationDialogService.confirm(
        'Warning - Unsaved Changes',
        '<p>You have made changes to this page which have not been saved!</p><p>Are you sure you want to leave this page?</p>',
        'Save Changes'
      ).then((confirmed: boolean) => confirmed)
    : true;
};

// Provide the CanDeactivate guard
export const canDeactivateGuardProvider = {
  provide: CAN_DEACTIVATE_GUARD,
  useValue: CanDeactivateGuard
};


export interface FormComponent {
  form: FormGroup;
  duelForm: FormGroup;
}
