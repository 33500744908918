<div id="root">
  <div class="fixed-background">
    <main>
      <div class="container">
        <div class="row justify-content-md-center h-100">
          <div class="col-sm-12 col-md-8 col-lg-7 col-xl-6 align-self-center">
            <div class="card auth-card error-page">
              <div class="form-side">
                <div class="logo">
                  <img alt="logo" src="../../../../assets/images/logo-light-text.png" />
                </div>
                <p class="text-center">Ooops... looks like an error occurred!</p>
                <p class="mb-0 text-muted text-small mb-0">
                  Error Code
                </p>
                <p class="display-1 font-weight-bold mb-5">404</p>
                <button class="btn btn-primary btn-lg" (click)="goBack()" type="submit">
                  Go Back Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
