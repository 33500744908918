<div class="card form-card">
  <div class="card-body">
    <div class="img-picker-height">
      <img [src]="safeSrc" alt="Place image title" *ngIf="safeSrc != null">
    </div>
  </div>

  <footer class="card-footer text-right">
    <div class="btn-group{{className}} custom-btn-group">
      <button class="btn btn-primary" type="button" (click)="upload()">
        <i class="fas fa-file-upload"></i>
      </button>

      <a *ngIf="isUploaded" class="btn btn-primary" (click)="download()">
        <i class="fas fa-file-download mt-0"></i>
      </a>

      <button *ngIf="isUploaded" class="btn btn-danger" type="button" (click)="remove()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </footer>
</div>
