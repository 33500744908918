import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-confirmation-special-dialog',
  templateUrl: './confirmation-special-dialog.component.html',
})
export class ConfirmationSpecialDialogComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() btnOkText!: string;
  @Input() btnCancelText!: string;
  @Input() closeTime!: number;
  public onClose!: Subject<boolean>;

  constructor(private activeModal: BsModalRef, private httpService: HttpService) { }

  ngOnInit() {
    this.autoClose();
    this.onClose = new Subject();
  }

  private autoClose() {
    if (this.closeTime > 999) {
      setTimeout(() => {
        this.accept();
      }, this.closeTime);
    }
  }

  public decline() {
    this.onClose.next(true);
    this.activeModal.hide();
  }

  public accept() {
    this.httpService.isSaveForm = true;
    this.onClose.next(true);
    this.activeModal.hide();
  }

  public dismiss() {
    this.onClose.next(true);
    this.activeModal.hide();
  }
}
