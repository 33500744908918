import { Injectable } from '@angular/core';
import { Observable, from, throwError, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

export interface ISignInCredentials {
  username: string;
  password: string;
  IsUserOnly: boolean;
  isNewUser?: boolean,
  Code?: string,
  Provider?: string,
  PhoneNumber?: string,
  IsRegistered?: string,
  IsDirectLogin?: boolean



}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  Id: string;
  Token: string;
  Password: string;
  FormType: string;
  isPortal: boolean;
}


export interface CreatPassword {
  Password: string;
  Email: string
}

export interface IVerifyEmail {
  Token: string;
  Email: string
}

export interface IVerifyCode {
  Email: string;
  Code: string
}

export interface IVerifyPhone  {
  Email: string;
  PhoneNumber: string;
}

export interface IVerifyPhoneCode extends IVerifyPhone  {
  Code: string
}

@Injectable({ providedIn: 'root' })
export class AuthService {


  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router
  ) { }

  signIn(credentials: ISignInCredentials): Observable<{success: boolean, email?: string, phoneNumber?: string}> {
    let that = this;
    let body = "";
    let url = "token";
    
    let regiScope = ["ApplicantPortal", "IsUserOnly"];
      if(credentials.isNewUser){
        regiScope.push("NewUser")
    }
    let appPortal = credentials.Provider ? ",ApplicantPortal" : ""
    let loginScope = (credentials.IsRegistered? "registered":
    credentials.Code && (credentials.Provider ? credentials.Code + "," + credentials.Provider : "") + appPortal);

    //console.log('scope---scope', appPortal, loginScope)
   let scope = credentials.isNewUser ? regiScope : loginScope
        
    if (credentials.IsUserOnly == true) {
      body = "username=" + credentials.username + "&password=" + encodeURIComponent(credentials.password) + "&grant_type=password&Scope=" + scope;
    }
    else {
      body = "username=" + credentials.username + "&password=" + encodeURIComponent(credentials.password) + "&grant_type=password&Scope=ApplicantPortal";
    }

    if(credentials.IsDirectLogin){
      let scope1 = ["ApplicantPortal", "IsUserDirectLogin"];
      body = "username=" + credentials.username + "&password=" + encodeURIComponent(credentials.password) + "&grant_type=password&Scope=" + scope1;
    }


    return that.http
      .post(url, body)
      .pipe(
        map((response: any) => {
          //console.log('response-----', response)

          if (response && response.isNumberNotConfirmed == "true") {
            this.toastr.error("Please complete your registraion process", "");
            return { success: false, email: response.email };
          }


          if (response && response.verification_code) {
            // this.toastr.success(response.verification_code, "");
            localStorage.setItem(
              "code",
              JSON.stringify({
                username: response.userName,
                codeSent: true,
              })
            );
            return { success: false, phoneNumber: response.phoneNumber };
          }

          if (response.closeAlertMessage) {
            that.toastr.warning(response.closeAlertMessage, "System Alert", {
              timeOut: 0,
              extendedTimeOut: 0,
              enableHtml: true,
            });
            return {success: false, email: response.email};
          }
          let token = response && response.access_token;
          if (token) {
            localStorage.setItem('currentUser', JSON.stringify({
              token: token,
              userName: response.fullUserName ? response.fullUserName : response.userName,
              userId: response.userId,
              tokenLife: response.expires_in / 3600,
              tokenTime: new Date().getTime(),
            }));
            return {success: true, email: response.email };
          }
          else {
            return {success: false, email: response.email };
          }
        }),
        catchError(error => {
          return throwError(error);
        }));
  }

  signOut() {
    this.logOut()
      .subscribe((res) => {
        localStorage.removeItem('currentUser');
        this.router.navigate(['authentication']);
      });
  }

  logOut() {
    return this.http.post("Account/Logout", {})
      .pipe(map((res) => res));
  }

  sendPasswordEmail(email: any) {
    let url = 'account/ForgotPasswordQCASS';
    var body = {
      Email: email,
      isPortal: true
    };
    return this.http
      .post(url, body)
      .pipe(
        map((response: any) => {
          if (response === true) {
            return response;
          } else {
            return false;
          }
        }),
        catchError(error => {
          return throwError(error);
        }));
  }

  resetPassword(model: IPasswordReset) {
    let url = "Account/ResetPasswordQCASS";
    return this.http
      .post(url, model)
      .pipe(
        map((response: any) => {
          if (response === true) {
            return response;
          } else {
            return false;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }



  private _listners = new Subject<any>();

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  filter(filterBy: string) {
    this._listners.next(filterBy);
  }

  currentUser(): any {
    return JSON.parse(localStorage.getItem('currentUser')!);
  }

  userName(): string {
    let currentUser = this.currentUser();
    let name: string = (currentUser && currentUser.userName) ? currentUser.userName : '';
    return name;
  }

  resetPasswordQccms(emailAddress: string) {
    let url = 'account/forgotpassword';
    var body = {
      Email: emailAddress
    };
    return this.http
      .post(url, body)
      .pipe(
        map((response: any) => {
          if (response === true) {
            return response;
          } else {
            return false;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  verifyEamil(model: IVerifyEmail) {
    let url = "Account/VerifyEmailQCASS";
    return this.http
      .post(url, model)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  confirmEmailCode(user: IVerifyCode): Observable<string> {
    let url = "Account/ConfirmEmail";
    return this.http
      .post(url, user)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  resendCode(provider: string, username: string, phoneNumber: string, isForgotPassword: boolean): Observable<boolean> {
    let url = "Account/ReSendCodeConfirmation";
    let body = { UserName: username, Provider: provider, PhoneNumber: phoneNumber, isForgotPassword: isForgotPassword };
    return this.http
      .post(url, body)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }


  confirmPhoneNumber(user: IVerifyPhoneCode): Observable<string> {
    let url = "Account/ConfirmPhoneNumber";
    return this.http
      .post(url, user)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  createPasswordUser(user: CreatPassword): Observable<string> {
    let url = "Account/RegisterCreatePassword";
    return this.http
      .post(url, user)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  verifyPhoneNumber(user: IVerifyPhone): Observable<string> {
    let url = "Account/VerifyPhoneNumber";
    return this.http
      .post(url, { Email: user.Email, PhoneNumber: user.PhoneNumber })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }

  sendCode(provider :any, username :any): Observable<boolean> {
    let url = "Account/SendCode";
    let body = { UserName: username, Provider: provider };
    return this.http
      .post(url, body)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        }));
  }


  confirmPasswordReset(model: any): Observable<boolean> {
    let url = "Account/ResetPasswordQCASS";
    return this.http
      .post(url, model)
      .pipe(
      map((response: any) => {
        if (response === true) {
          return response;
        } else {
          return false;
        }
      }),
      catchError((error: any) => {
        return throwError(error);
      }));
  }


}
