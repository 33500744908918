<div id="app-container" [className]="sidebar.containerClassnames">
  
  <app-topnav></app-topnav>
  <!--<app-sidebar></app-sidebar>-->
  <main>
    <!--<div class="breadcrumbPanel fixed-top">
      <app-breadcrumb></app-breadcrumb>
    </div>-->
    <div class="container-fluid">
      <!-- <ng-snotify></ng-snotify> -->
      <router-outlet></router-outlet>
      
    </div>
  </main>
  <app-footer></app-footer>
</div>
