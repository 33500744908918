import { Injectable, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from "../../../environments/environment";
import { init } from 'filestack-js';
import { saveAs } from 'file-saver';

@Injectable()
export class ImagePickerService {
  filepicker: any;
  options: any;
  filepickerBaseUrl: string = 'https://www.filepicker.io/api/file/';
  transformOptions: any = {
    quality: {
      value: 80
    }
  };
  @Output() uploadDone: EventEmitter<any> = new EventEmitter<any>();
  constructor(private cookieService: CookieService) {
    const apiKey = environment.filePickerApi.key;
    const security = {
      "security": {
        "policy": "",
        "signature": ""
      }
    };
    security["security"]["policy"] = environment.filePickerApi.policy;
    security["security"]["signature"] = environment.filePickerApi.signature;
    this.filepicker = init(apiKey, security);
    this.options = {
      fromSources: ['local_file_system'],
      storeTo: {
        location: environment.filePickerApi.location,
        path: environment.filePickerApi.path
      },
      disableTransformer: false,
      onOpen: (opened :any) => {
        this.cookieService.delete('session');
      },
      onUploadDone: (pickerResponse: any) => {
        this.uploadDone.emit(pickerResponse.filesUploaded);
      },
      accept:'image/*'
    };
  }

  open(options: any) {
    let that = this;
    that.options = Object.assign({}, that.options, options);
    that.filepicker.picker(that.options).open();
  }

  remove(fileHandle: string) {
    let that = this;
    const promise = new Promise((resolve, reject) => {
      that.filepicker.remove(fileHandle)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err :any) => {
          reject(err);
        });
    });
    return promise;
  }

  get(fileHandle: string) {
    let that = this;
    const promise = new Promise((resolve, reject) => {
      that.filepicker.metadata(fileHandle)
        .then((fileMetData: any) => {
          resolve(fileMetData);
        })
        .catch((err :any) => {
          reject(err);
        });
    });
    return promise;
  }

  transform(url: string) {
    let that = this;
    const transformedUrl = that.filepicker.transform(url, that.transformOptions);
    return that.filepicker.storeURL(transformedUrl);
  }

  download(fileHandle: string) {
    let that = this;
    that.get(fileHandle)
      .then((fileMetaData: any) => {
        that.filepicker.retrieve(fileHandle)
          .then((blob: any) => {
            saveAs(blob, fileMetaData.filename);
          })
          .catch((err :any) => {
            console.log(err);
          });
      });
  }

  getUrl(fileHandle :any) {
    return this.filepickerBaseUrl + fileHandle + '?policy=' + environment.filePickerApi.policy + '&signature=' + environment.filePickerApi.signature;
  }
}
