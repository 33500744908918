import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsComponent } from './views.component';
import { ViewRoutingModule } from './views.routing';
import { LayoutContainersModule } from 'src/app/containers/layout/layout.containers.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../shared/shared.module';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from '../../environments/environment';
//import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

@NgModule({
  declarations: [ViewsComponent],
  imports: [
    LayoutContainersModule,
    CommonModule,
    ModalModule.forRoot(),
    ViewRoutingModule,
    SharedModule,
    UserIdleModule.forRoot(environment.userIdle),
    //SnotifyModule
  ],
  providers: [
    // { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    // SnotifyService
  ]
})
export class ViewsModule { }
