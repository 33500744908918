import { Component, OnInit, OnDestroy } from '@angular/core';
import { ISidebar, SidebarService } from '../containers/layout/sidebar/sidebar.service';
import { Subscription } from 'rxjs';
//import { UserIdleService } from 'angular-user-idle';
import { AuthService } from './authentication/authentication.service';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html'
})
export class ViewsComponent implements OnInit, OnDestroy {

  sidebar: ISidebar | any;
  subscription!: Subscription;
  user: any = {};
  subscriptions: any = [];

  constructor(
    private sidebarService: SidebarService,
    //private userIdle: UserIdleService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.subscription = this.sidebarService.getSidebar().subscribe(
      res => {
        this.sidebar = res;
      },
      err => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
    this.checkTokenLife();
    //this.userIdle.startWatching();
    //this.subscriptions.push((this.userIdle.onTimerStart().subscribe()));
    // this.subscriptions.push((this.userIdle.onTimeout().subscribe(() => {
    //   this.logout();
    // })));
  }

  checkTokenLife() {
    this.user = JSON.parse(localStorage.getItem('currentUser')!);
    if (this.user) {
      let tokenTime = ((new Date().getTime() - this.user.tokenTime) / 1000) / 3600;
      if (tokenTime > this.user.tokenLife) {
        this.authService.signOut();
      }
    }
    else {
      this.authService.signOut();
    }
  }

  logout() {
    this.authService.signOut();
    //this.userIdle.stopTimer();
    this.subscriptions.forEach((subscription :any) => subscription.unsubscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
