<div class="menu" (click)="menuClicked($event)">
  <div class="main-menu">
    <!-- <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}"> -->
      <ul class="list-unstyled">
        <ng-container *ngFor="let item of menuItems">
          <li *ngxPermissionsOnly="[item.permissions]" [routerLinkActive]="item.subs && item.subs.length != 0 ? '' : 'active'" [routerLinkActiveOptions]="{exact:false}">
            <a *ngIf="item.newWindow" [routerLink]="[item.to]" rel="noopener noreferrer" target="_blank">
              <i [ngClass]="item.icon"></i><span class="hide-menu"> {{item.label | translate}}</span>
            </a>
            <a *ngIf="!item.newWindow && item.subs &&  item.subs.length>0"
               (click)="openSubMenu($event,item); false" [routerLink]="[item.to]">
              <i [ngClass]="item.icon"></i> <span class="hide-menu">{{item.label | translate}}</span>
            </a>
            <a *ngIf="!item.newWindow && (!item.subs || (item.subs &&  item.subs.length===0))"
               (click)="changeSelectedParentHasNoSubmenu(item.id); false" [routerLink]="item.to">
              <i [ngClass]="item.icon"></i> <span class="hide-menu">{{item.label | translate}}</span>
            </a>
          </li>
        </ng-container>

      </ul>
    <!-- </perfect-scrollbar> -->
  </div>
  <footer class="footer sibebar-footer"><span> Copyright © 2011-2020 </span><a href="http://www.probase.co.uk" target="_blank">Probase Applications Ltd</a></footer>
  <!--<div class="sub-menu">
    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
      <ul class="list-unstyled" *ngFor="let item of menuItems" [attr.data-parent]="item.id"
          [ngClass]="{'d-block': (selectedParentMenu === item.id && viewingParentMenu==='') || viewingParentMenu === item.id}">
        <ng-container *ngFor="let sub of item.subs">
          <li *ngxPermissionsOnly="[sub.permissions]" [routerLinkActive]="sub.subs && sub.subs.length != 0 ? '' : 'active'" [routerLinkActiveOptions]="{exact:false}">
            <a *ngIf="sub.newWindow" [routerLink]="sub.to" rel="noopener noreferrer" target="_blank">
              <i [ngClass]="sub.icon"></i>
              <span class="d-inline-block">{{sub.label | translate}}</span>
            </a>
            <a *ngIf="!sub.newWindow && (!sub.subs || sub.subs.length<=0)" [routerLink]="[sub.to]"
               routerLinkActive="active">
              <i [ngClass]="sub.icon"></i>
              <span class="d-inline-block">{{sub.label | translate}}</span>
            </a>
            <a (click)="collapseSub.toggle()" *ngIf="sub.subs && sub.subs.length > 0"
               class="rotate-arrow-icon opacity-50 c-pointer">
              <i class="simple-icon-arrow-down"></i>
              <span class="d-inline-block">{{sub.label | translate}}</span>
            </a>
            <div [collapse]="false" #collapseSub="bs-collapse" [isAnimated]="true">
              <div *ngIf="sub.subs && sub.subs.length > 0">
                <ul class="list-unstyled inner-level-menu">
                  <ng-container *ngFor="let deepSub of sub.subs">
                    <li *ngxPermissionsOnly="[deepSub.permissions]" [routerLinkActive]="deepSub.subs && deepSub.subs.length != 0 ? '' : 'active'" [routerLinkActiveOptions]="{exact:false}">
                      <a *ngIf="deepSub.newWindow" [routerLink]="deepSub.to" rel="noopener noreferrer"
                         target="_blank">
                        <i [ngClass]="deepSub.icon"></i>
                        <span class="d-inline-block">{{deepSub.label | translate}}</span>
                      </a>
                      <a *ngIf="!deepSub.newWindow && (!deepSub.subs || deepSub.subs.length<=0)"
                         [routerLink]="[deepSub.to]" routerLinkActive="active">
                        <i [ngClass]="deepSub.icon"></i>
                        <span class="d-inline-block">{{deepSub.label | translate}}</span>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>

        </ng-container>
      </ul>
    </perfect-scrollbar>
  </div>-->
</div>
