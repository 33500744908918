import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationSpecialDialogComponent } from './confirmation-special-dialog.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfirmationSpecialDialogService {
  constructor(private modalService: BsModalService) {
  }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    closeTime: number = 0): Observable<boolean> {
    const modalRef :any = this.modalService.show(ConfirmationSpecialDialogComponent, { class: 'modal-md', backdrop: 'static', keyboard: false });
    modalRef.content.title = title;
    modalRef.content.message = message;
    modalRef.content.btnOkText = btnOkText;
    modalRef.content.btnCancelText = btnCancelText;
    modalRef.content.closeTime = closeTime;
    return modalRef.content.onClose.pipe(map(result => {
      return result
    }));
  }
}
